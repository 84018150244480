import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import Image from "./Image";

const SectionalDoors = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="sekcionni_vrati/1.jpg"
              alt="Секционна врата"
              style={{ height: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="sekcionni_vrati/2.jpg"
              alt="Секционна врата"
              style={{ height: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="sekcionni_vrati/3.jpg"
              alt="Секционна врата"
              style={{ height: "300px" }}
            />
          </Carousel.Item>
        </Carousel>
        <div className="row justify-content-center my-3">
          <Link className="btn btn-primary" to="/gallery/#sekcionni_vrati" activeClassName="active">
            Към Галерията
          </Link>
        </div>
      </div>
      <div className="col-lg-6">
        <div>
          Секционните врати са най-съвременните, сигурни и естетически изпипани гаражни врати. Те се
          изработват от „сандвич“ панели (два външни слоя от галванизирана стомана с дебелина 0.5мм
          и полиуретан за вътрешен слой). Предимства:
          <ul>
            <li>Добра топло- и шумоизолация</li>
            <li>Спестяване на място чрез вертикалното си отваряне</li>
            <li>Защитава от външни атмосферни условия (прах, влага, насекоми)</li>
            <li>Защита от наранявания (прещипвания) по време на експлоатация</li>
            <li>Не се нуждаят от голяма поддръжка</li>
            <li>Дълъг живот на експлоатация</li>
            <li>Разнообразия от цветове и дизайни</li>
            <li>
              Възможност за вграждане на проходна/пешеходна врата, както и прозорци за допълнителна
              светлина в помещението
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

SectionalDoors.propTypes = {
  frontmatter: PropTypes.object,
};

SectionalDoors.defaultProps = {
  frontmatter: {},
};

export default SectionalDoors;
